import { ZodSchema } from "zod";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { getFirebaseAuth } from "logic/internals/apis/firebase/firebase-auth";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { Logger } from "logic/internals/logging/logger";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

// Unified error type for all possible errors
export type UnifiedError =
  | FetchBaseQueryError
  | {
      status: "CUSTOM_ERROR";
      error: TransportFailure;
    };

// Define the type for the enhanced base query
type TBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  UnifiedError,
  { dataSchema?: ZodSchema; skipAuthentication?: boolean },
  FetchBaseQueryMeta
>;

/**
 * A wrapper around the base query that adds data validation with zod,
 * Firebase authentication for automatically injecting the token,
 * and error logging functionality.
 *
 * @param baseQuery The base query function to be wrapped.
 * @returns A modified baseQuery with Firebase authentication, zod validation, and error logging.
 *
 * @example
 * // Import necessary dependencies
 * import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
 * import { z } from 'zod';
 * import { customBaseQuery } from './base-query';
 *
 * // Define a schema for the expected response
 * const userSchema = z.object({
 *   id: z.number(),
 *   name: z.string(),
 *   email: z.string().email()
 * });
 *
 * // Define the User type using z.infer
 * type User = z.infer<typeof userSchema>;
 *
 * // Create an API with customBaseQuery
 * export const api = createApi({
 *   baseQuery: customBaseQuery(fetchBaseQuery({ baseUrl: '/api' })),
 *   endpoints: (builder) => ({
 *     getUser: builder.query<User, number>({
 *       query: (id) => `users/${id}`,
 *       extraOptions: {
 *         dataSchema: userSchema
 *       }
 *     })
 *   })
 * });
 *
 * // In your component:
 * const { data, error, isLoading } = api.useGetUserQuery(1);
 * // The data will be validated against the userSchema,
 * // and the Firebase token will be automatically injected into the request.
 */
export const customBaseQuery: (baseQuery: TBaseQuery) => TBaseQuery =
  (baseQuery: TBaseQuery) => async (args, api, extraOptions) => {
    // Step 1: Attempt to get the Firebase authentication token (if not skipped)
    let token: string | null = null;

    if (!extraOptions?.skipAuthentication) {
      try {
        // Get the current user from Firebase
        const currentUser = getFirebaseAuth().currentUser;
        if (currentUser) {
          // If there's a current user, fetch their ID token
          token = await currentUser.getIdToken();
        }
      } catch (error) {
        // If there's an error fetching the token, log it and return an authentication failure
        Logger.logError(
          "customBaseQuery:fetch:token-error",
          error instanceof Error ? error : new Error(String(error))
        );
        return { error: { status: "CUSTOM_ERROR", error: TransportFailure.AuthenticationFailure } };
      }

      // Step 2: Check if we successfully obtained a token (only if authentication is not skipped)
      if (token === null) {
        const url = typeof args === "string" ? args : args.url;
        // If no token is available, log the error and return an authentication failure
        Logger.logError(
          `customBaseQuery:fetch:no-token for ${url}`,
          new Error("No authentication token available")
        );
        return { error: { status: "CUSTOM_ERROR", error: TransportFailure.AuthenticationFailure } };
      }
    }

    // Step 3: Enhance the query arguments with the authentication token (if available) and base URL
    const enhancedArgs: FetchArgs =
      typeof args === "string"
        ? {
            url: `${EnvironmentVariables.MAIN_API_URL}${args}`,
            headers: token ? { Authorization: `Bearer ${token}` } : {},
          }
        : {
            ...args,
            url: `${EnvironmentVariables.MAIN_API_URL}${args.url}${args.params ? `?${new URLSearchParams(args.params).toString()}` : ""}`,
            headers: {
              ...args.headers,
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          };

    try {
      // Step 4: Call the original baseQuery function with the enhanced arguments
      const returnValue = await baseQuery(enhancedArgs, api, extraOptions);

      // Step 5: Retrieve the Zod schema from extraOptions for data validation
      const zodSchema = extraOptions?.dataSchema;
      const { data } = returnValue;

      // Step 6: Validate the response data if both data and schema are present
      if (data && zodSchema) {
        try {
          // Attempt to parse the data with the Zod schema
          const validationResult = zodSchema.safeParse(data);
          if (!validationResult.success) {
            // If validation fails, log the error and return an unexpected response error
            Logger.logError(
              `customBaseQuery:fetch:validation-error for ${enhancedArgs.url}`,
              validationResult.error,
              {
                data,
              }
            );
            return {
              error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse },
            };
          }
        } catch (error) {
          // If validation fails, log the error and return an unexpected response error
          Logger.logError(
            `customBaseQuery:fetch:validation-error for ${enhancedArgs.url}`,
            error instanceof Error ? error : new Error(String(error)),
            {
              data,
            }
          );
          return { error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse } };
        }
      }

      // Step 7: If all checks pass, return the original return value
      return returnValue;
    } catch (error) {
      // Step 8: Handle any errors that occur during the query execution

      // Check if it's a connection failure (TypeError)
      if (error instanceof Error && error.name === "TypeError") {
        // Log the connection failure with request details
        Logger.logError("customBaseQuery:fetch:connection-failure", error, {
          request: {
            path: enhancedArgs.url,
            method: enhancedArgs.method,
            body: enhancedArgs.body as Record<string, unknown>,
          },
        });
        return { error: { status: "FETCH_ERROR", error: TransportFailure.ConnectionFailure } };
      }

      // For any other type of error, treat it as an unexpected response
      Logger.logError(
        "customBaseQuery:fetch:unexpected-response",
        error instanceof Error ? error : new Error(String(error)),
        {
          request: {
            path: enhancedArgs.url,
            method: enhancedArgs.method,
            body: enhancedArgs.body as Record<string, unknown>,
          },
          response: {
            status: (error as FetchBaseQueryError)?.status,
          },
        }
      );
      return { error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse } };
    }
  };
