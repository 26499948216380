// Redux toolkit store config
import { configureStore } from "@reduxjs/toolkit";
// Web Sockets Middleware config
import { webSocketMiddleware } from "./middleware/websockets";
import {
  WebSocketConnectionConfigKeys,
  WebSocketConnectionsConfig,
} from "./middleware/websockets/types";
// Modules Web Sockets Config
import { summariesWSConnectionConfig } from "./modules/summaries/websocket.config";
import { userInterviewsWSConnectionConfig } from "./modules/user-interviews/websocket.config";
import { summariesConversationsWSConnectionConfig } from "./modules/summaries-conversations/websocket.config";
import { userInterviewConversationsWSConnectionConfig } from "./modules/user-interview-conversations/websocket.config";
// Reducers and APIs
import { reducer as surveys } from "./modules/surveys/reducer";
import { customScriptReducer } from "./modules/custom-script/slice";
import { ragApi, ragReducer } from "./modules/rag/slice";
import { audiencesApi, audiencesReducer } from "./modules/audiences/slice";
import { problemsApi, problemsReducer } from "./modules/problems/slice";
import { solutionsApi, solutionsReducer } from "./modules/solutions/slice";
import { researchGoalApi, researchGoalReducer } from "./modules/research-goal/slice";
import { historyApi, historyReducer } from "./modules/history/slice";
import { releaseNotesApi, releaseNotesReducer } from "./modules/release-notes/slice";
import { subscriptionApi, subscriptionReducer } from "./modules/subscription/slice";
import { currentUserApi, currentUserReducer } from "./modules/users/slice";
import { plansApi, plansReducer } from "./modules/plans/slice";
import { ssoProviderApi, ssoProviderReducer } from "./modules/sso-provider/slice";
import { researchAssistantApi, researchAssistantReducer } from "./modules/research-assistant/slice";
import { knowledgeGraphApi, knowledgeGraphReducer } from "./modules/knowledge-graph/slice";
import { userInterviewsApi, userInterviewsReducer } from "./modules/user-interviews/slice";
import {
  userInterviewConversationsApi,
  userInterviewConversationsReducer,
} from "./modules/user-interview-conversations/slice";
import { summaryApi, summaryReducer } from "./modules/summaries/slice";
import {
  summaryConversationsApi,
  summaryConversationsReducer,
} from "./modules/summaries-conversations/slice";
import { syntheticUsersApi, syntheticUsersReducer } from "./modules/synthetic-users/slice";
import { studyApi, studyReducer } from "./modules/study/slice";
import { projectsApi, projectsReducer } from "./modules/projects/slice";
import { workspacesApi, workspacesReducer } from "./modules/workspaces/slice";

const webSocketsConnectionsConfig = {
  [WebSocketConnectionConfigKeys.UserInterviews]: userInterviewsWSConnectionConfig,
  [WebSocketConnectionConfigKeys.UserInterviewConversation]:
    userInterviewConversationsWSConnectionConfig,
  [WebSocketConnectionConfigKeys.Summary]: summariesWSConnectionConfig,
  [WebSocketConnectionConfigKeys.SummaryConversation]: summariesConversationsWSConnectionConfig,
  // Additional connections can be configured similarly
} as unknown as WebSocketConnectionsConfig;

export const store = configureStore({
  reducer: {
    surveys,
    ...customScriptReducer,
    ...ragReducer,
    ...audiencesReducer,
    ...problemsReducer,
    ...solutionsReducer,
    ...researchGoalReducer,
    ...historyReducer,
    ...releaseNotesReducer,
    ...subscriptionReducer,
    ...currentUserReducer,
    ...plansReducer,
    ...ssoProviderReducer,
    ...researchAssistantReducer,
    ...knowledgeGraphReducer,
    ...userInterviewsReducer,
    ...summaryReducer,
    ...userInterviewConversationsReducer,
    ...summaryConversationsReducer,
    ...syntheticUsersReducer,
    ...studyReducer,
    ...projectsReducer,
    ...workspacesReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(webSocketMiddleware(webSocketsConnectionsConfig))
      .concat(ragApi.middleware)
      .concat(audiencesApi.middleware)
      .concat(problemsApi.middleware)
      .concat(solutionsApi.middleware)
      .concat(researchGoalApi.middleware)
      .concat(historyApi.middleware)
      .concat(releaseNotesApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(currentUserApi.middleware)
      .concat(plansApi.middleware)
      .concat(ssoProviderApi.middleware)
      .concat(researchAssistantApi.middleware)
      .concat(knowledgeGraphApi.middleware)
      .concat(userInterviewsApi.middleware)
      .concat(userInterviewConversationsApi.middleware)
      .concat(summaryApi.middleware)
      .concat(summaryConversationsApi.middleware)
      .concat(syntheticUsersApi.middleware)
      .concat(studyApi.middleware)
      .concat(projectsApi.middleware)
      .concat(workspacesApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
