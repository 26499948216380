import { store } from "../..";
import { setProjectAudiences } from "../../modules/audiences/slice";
import { resetCustomScript } from "../../modules/custom-script/slice";
import { historyApi } from "../../modules/history/slice";
import { knowledgeGraphApi } from "../../modules/knowledge-graph/slice";
import { setProjectProblems } from "../../modules/problems/slice";
import { Project } from "../../modules/projects/types";
import { ragApi } from "../../modules/rag/slice";
import { resetResearchAssistant } from "../../modules/research-assistant/slice";
import { resetResearchGoal } from "../../modules/research-goal/slice";
import { setProjectSolutions } from "../../modules/solutions/slice";
import { resetStudy } from "../../modules/study/slice";
import { resetSummaryConversations } from "../../modules/summaries-conversations/slice";
import { resetSummary } from "../../modules/summaries/slice";
import { getProjectSurveys } from "../../modules/surveys/actions";
import { resetSyntheticUsers } from "../../modules/synthetic-users/slice";
import { resetUserInterviewConversations } from "../../modules/user-interview-conversations/slice";
import { resetUserInterviews } from "../../modules/user-interviews/slice";

export const handleChangeProject = (project: Project) => {
  // set data that comes with the project
  store.dispatch(setProjectAudiences({ audiences: project.syntheticUsers }));
  store.dispatch(setProjectProblems({ problems: project.problems }));
  store.dispatch(setProjectSolutions({ solutions: project.solutions }));

  // fetch data for project that is not included in the project object
  store.dispatch(
    historyApi.endpoints.getHistory.initiate({ projectId: project.id }, { forceRefetch: true })
  );
  store.dispatch(
    ragApi.endpoints.getRagFiles.initiate({ projectId: project.id }, { forceRefetch: true })
  );
  store.dispatch(getProjectSurveys({ projectId: project.id }));

  // reset data that is not included in the project object
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetCustomScript());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetStudy());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(resetSummary());
  store.dispatch(resetSummaryConversations());
  store.dispatch(knowledgeGraphApi.util.invalidateTags(["KnowledgeGraph"]));
};
