// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { UserInterviewConversation, UserInterviewConversationMessage } from "./types";
// Schemas
import { UserInterviewConversationSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// WebSocket config
import { WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
// Zod
import { z } from "zod";

// Create the API slice
export const userInterviewConversationsApi = createApi({
  reducerPath: "userInterviewConversationsApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  endpoints: (builder) => ({
    /***** --- Get User Interview Conversations Query --- *****/
    getUserInterviewConversations: builder.query<
      UserInterviewConversation[],
      {
        interviewId: string;
      }
    >({
      query: ({ interviewId }) => ({
        url: `/conversations/userInterview/${interviewId}`,
        method: "GET",
      }),
      extraOptions: {
        dataSchema: z.array(UserInterviewConversationSchema),
        skipAuthentication: true,
      },
    }),
  }),
});

const userInterviewConversationsSlice = createSlice({
  name: "userInterviewConversations",
  initialState,
  reducers: {
    /***** --- Reset User Interview Conversations --- *****/
    resetUserInterviewConversations: () => initialState,
    /***** --- Handle WebSocket Connection --- *****/
    connectUserInterviewConversationsWS: {
      prepare: (userInterviewId: string, followUpQuestion: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.UserInterviewConversation}-${userInterviewId}`,
          resourceId: userInterviewId,
          queryParams: `message=${followUpQuestion}`,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle WebSocket Disconnection --- *****/
    disconnectUserInterviewConversationsWS: {
      prepare: (userInterviewId: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.UserInterviewConversation}-${userInterviewId}`,
          resourceId: userInterviewId,
        },
      }),
      reducer: (state) => ({
        ...state,
        loading: state.loading - 1,
      }),
    },
    /***** --- Handle Streaming User Interview Conversations --- *****/
    streamUserInterviewConversations: (
      state,
      action: PayloadAction<{ clientSideId: string; message: UserInterviewConversationMessage }>
    ) => {
      const { clientSideId, message } = action.payload;

      const prevConversation = state?.data?.[clientSideId]?.find(
        (conversation) => conversation.id === clientSideId
      );

      const newConversation: UserInterviewConversation = {
        id: clientSideId,
        userInterviewId: clientSideId,
        message: prevConversation
          ? `${prevConversation.message}${message.message}`
          : message.message,
        createdAt: new Date(),
        type: "llm",
      };

      state.data = {
        ...state.data,
        [clientSideId]: prevConversation
          ? state.data?.[clientSideId]?.map((conversation) =>
              conversation.id === clientSideId
                ? {
                    ...conversation,
                    message: `${conversation.message}${message.message}`,
                  }
                : conversation
            ) ?? []
          : [...(state.data?.[clientSideId] ?? []), newConversation],
      };
    },
    /***** --- Handle Adding User Interview Conversation --- *****/
    addUserInterviewConversation: (
      state,
      action: PayloadAction<{ userInterviewId: string; message: string }>
    ) => {
      const { userInterviewId, message } = action.payload;

      const newQuestion: UserInterviewConversation = {
        id: "tempId",
        userInterviewId,
        message,
        createdAt: new Date(),
        type: "llm",
      };

      state.data = {
        ...state.data,
        [userInterviewId]: [...(state.data?.[userInterviewId] ?? []), newQuestion],
      };
      state.loading += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        userInterviewConversationsApi.endpoints.getUserInterviewConversations.matchPending,
        (state) => {
          state.loading += 1;
        }
      )
      .addMatcher(
        isAnyOf(
          userInterviewConversationsApi.endpoints.getUserInterviewConversations.matchFulfilled,
          userInterviewConversationsApi.endpoints.getUserInterviewConversations.matchRejected
        ),
        (state) => {
          state.loading -= 1;
        }
      )
      /***** --- Handle Fetch User Interview Conversations Fulfilled --- *****/
      .addMatcher(
        userInterviewConversationsApi.endpoints.getUserInterviewConversations.matchFulfilled,
        (state, action) => {
          const { interviewId } = action.meta.arg.originalArgs;
          state.data = {
            ...state.data,
            [interviewId]: action.payload,
          };
        }
      )
      /***** --- Handle Fetch User Interview Conversations Rejected --- *****/
      .addMatcher(
        isAnyOf(
          userInterviewConversationsApi.endpoints.getUserInterviewConversations.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const {
  connectUserInterviewConversationsWS,
  disconnectUserInterviewConversationsWS,
  streamUserInterviewConversations,
  resetUserInterviewConversations,
  addUserInterviewConversation,
} = userInterviewConversationsSlice.actions;

// Export hooks
export const { useGetUserInterviewConversationsQuery } = userInterviewConversationsApi;

// Combine the reducers
export const userInterviewConversationsReducer = {
  [userInterviewConversationsApi.reducerPath]: userInterviewConversationsApi.reducer,
  userInterviewConversations: userInterviewConversationsSlice.reducer,
};
