// Redux Toolkit
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import {
  ResearchAssistantStatus,
  ResearchAssistantResponse,
  ResearchAssistant,
  ResearchAssistantStudyType,
} from "./types";
// Initial state
import { initialState } from "./initial-state";

// Create the API slice
export const researchAssistantApi = createApi({
  reducerPath: "researchAssistantApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  endpoints: (builder) => ({
    /***** --- Init Assistant Mutation --- *****/
    initAssistant: builder.mutation<ResearchAssistant, void>({
      query: () => ({
        url: "/researchAssistant",
        method: "POST",
      }),
    }),
    /***** --- Send Assistant Message Mutation --- *****/
    sendAssistantMessage: builder.mutation<
      ResearchAssistantResponse,
      {
        assistantId: string;
        message: string;
        study_type: ResearchAssistantStudyType;
        isLiteral?: boolean;
      }
    >({
      query: ({ assistantId, message, study_type, isLiteral }) => ({
        url: `/researchAssistant/chat/${assistantId}`,
        method: "POST",
        body: {
          message,
          study_type,
          lock: isLiteral,
        },
      }),
    }),
  }),
});

// Create the regular slice
const researchAssistantSlice = createSlice({
  name: "researchAssistant",
  initialState,
  reducers: {
    /***** --- Reset Research Assistant --- *****/
    resetResearchAssistant: () => initialState,
    /***** --- Mark Research Assistant As Completed --- *****/
    markResearchAssistantAsCompleted: (state) => {
      state.data = {
        ...state.data,
        status: ResearchAssistantStatus.COMPLETED,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(researchAssistantApi.endpoints.sendAssistantMessage.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(
          researchAssistantApi.endpoints.sendAssistantMessage.matchFulfilled,
          researchAssistantApi.endpoints.sendAssistantMessage.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Init Assistant Fulfilled --- *****/
      .addMatcher(researchAssistantApi.endpoints.initAssistant.matchFulfilled, (state, action) => {
        const { id } = action.payload;
        state.data = {
          ...state.data,
          id,
        };
      })
      /***** --- Handle Send Assistant Message Fulfilled --- *****/
      .addMatcher(
        researchAssistantApi.endpoints.sendAssistantMessage.matchFulfilled,
        (state, action) => {
          const parsedData = {
            question: action.payload.next_question,
            audience: action.payload.audience,
            researchGoal: action.payload.research_goal,
            problems: action.payload.problems,
            solution: action.payload.solution,
            customScript: action.payload.custom_script,
            status: action.payload.status,
          };

          state.data = {
            ...state.data,
            ...parsedData,
          };
        }
      )
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          researchAssistantApi.endpoints.initAssistant.matchRejected,
          researchAssistantApi.endpoints.sendAssistantMessage.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export hooks
export const { useInitAssistantMutation, useSendAssistantMessageMutation } = researchAssistantApi;

// Export actions
export const { resetResearchAssistant, markResearchAssistantAsCompleted } =
  researchAssistantSlice.actions;

// Combine the reducers
export const researchAssistantReducer = {
  [researchAssistantApi.reducerPath]: researchAssistantApi.reducer,
  researchAssistant: researchAssistantSlice.reducer,
};
