// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Solution } from "./types";
// Schemas
import { SolutionSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Transport failures
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

// Create the API slice
export const solutionsApi = createApi({
  reducerPath: "solutionsApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["Solutions"],
  endpoints: (builder) => ({
    /***** --- Add Solution Mutation --- *****/
    addSolution: builder.mutation<Solution, { projectId: string; description: string }>({
      query: ({ projectId, description }) => ({
        url: `/solutions`,
        method: "POST",
        body: { projectId, description },
      }),
      extraOptions: {
        dataSchema: SolutionSchema,
      },
    }),
    /***** --- Update Solution Mutation --- *****/
    updateSolution: builder.mutation<
      Solution,
      { projectId: string; solutionId: string; description: string }
    >({
      query: ({ projectId, solutionId, description }) => ({
        url: `/solutions/${solutionId}`,
        method: "PUT",
        body: {
          projectId,
          description,
        },
      }),
      extraOptions: {
        dataSchema: SolutionSchema,
      },
    }),
  }),
});

// Create the regular slice
export const solutionsSlice = createSlice({
  name: "solutions",
  initialState,
  reducers: {
    /***** --- Reset Solutions --- *****/
    resetSolutions: () => initialState,
    /***** --- Reset Selected Solution --- *****/
    resetSelectedSolution: (state) => {
      state.data.selectedSolution = undefined;
    },
    /***** --- Set Project Solutions --- *****/
    setProjectSolutions: (
      state,
      action: PayloadAction<{ solutions?: Solution[]; error?: TransportFailure }>
    ) => {
      state.error = action.payload.error;
      state.data.solutions = action.payload.solutions;
      state.data.selectedSolution = undefined;
    },
    /***** --- Set Study Solutions --- *****/
    setStudySolutions: (state, action: PayloadAction<{ solution?: Solution | null }>) => {
      state.data.solutions = action.payload.solution ? [action.payload.solution] : [];
      state.data.selectedSolution = action.payload.solution ?? undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          solutionsApi.endpoints.addSolution.matchPending,
          solutionsApi.endpoints.updateSolution.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          solutionsApi.endpoints.addSolution.matchFulfilled,
          solutionsApi.endpoints.addSolution.matchRejected,
          solutionsApi.endpoints.updateSolution.matchFulfilled,
          solutionsApi.endpoints.updateSolution.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Add Solution Optimistic update --- *****/
      .addMatcher(solutionsApi.endpoints.addSolution.matchPending, (state, action) => {
        const { requestId } = action.meta;
        // create a temporary solution with the request id as the id
        const tempSolution = {
          id: requestId,
          description: action.meta.arg.originalArgs.description,
          createdAt: new Date().toISOString(),
        };
        // add the temporary solution to the solutions state
        state.data.solutions = [tempSolution, ...(state.data.solutions || [])];
        // add the temporary solution to the selectedSolution state
        state.data.selectedSolution = tempSolution;
      })
      // handle server response and replace optimistic update with server data on addSolution
      .addMatcher(solutionsApi.endpoints.addSolution.matchFulfilled, (state, action) => {
        const { requestId } = action.meta;
        const createdSolution = action.payload;
        // find the temporary solution in the solutions state using the request id
        const tempSolutionIndex =
          state.data.solutions?.findIndex((solution) => solution.id === requestId) ?? -1;
        // if the temporary solution is found
        if (tempSolutionIndex !== -1 && state.data.solutions?.[tempSolutionIndex]) {
          // replace the temporary solution with the actual solution
          state.data.solutions[tempSolutionIndex] = createdSolution;
          // replace the temporary selected solution with the actual solution
          state.data.selectedSolution =
            state.data.selectedSolution?.id === requestId
              ? createdSolution
              : state.data.selectedSolution;
        }
      })
      // handle server response and remove temporary solution if error occurs on addSolution
      .addMatcher(solutionsApi.endpoints.addSolution.matchRejected, (state, action) => {
        const { requestId } = action.meta;
        // find the temporary solution in the solutions state using the request id
        const tempSolutionIndex =
          state.data.solutions?.findIndex((solution) => solution.id === requestId) ?? -1;
        // if the temporary solution is found
        if (tempSolutionIndex !== -1 && state.data.solutions?.[tempSolutionIndex]?.id) {
          // remove the temporary solution from the solutions state
          state.data.solutions?.splice(tempSolutionIndex, 1);
          // remove the temporary solution from the selectedSolutions state
          state.data.selectedSolution =
            state.data.selectedSolution?.id !== requestId ? state.data.selectedSolution : undefined;
        }
      })
      /***** --- Handle Update Solution Fulfilled --- *****/
      .addMatcher(solutionsApi.endpoints.updateSolution.matchFulfilled, (state, action) => {
        // get the solution id to update - ATM the BE is deleting the solution and creating a new one so the id returned won't match the id of the solution in the state
        const solutionToUpdateId = action.meta.arg.originalArgs.solutionId;
        const updatedSolution = action.payload;

        // Update the solutions and selectedSolution with the server data
        state.data.solutions = state.data.solutions?.map((solution) =>
          solution.id === solutionToUpdateId ? updatedSolution : solution
        );
        state.data.selectedSolution =
          state.data.selectedSolution?.id === solutionToUpdateId
            ? updatedSolution
            : state.data.selectedSolution;
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          solutionsApi.endpoints.addSolution.matchRejected,
          solutionsApi.endpoints.updateSolution.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { setProjectSolutions, setStudySolutions, resetSolutions, resetSelectedSolution } =
  solutionsSlice.actions;

// export hooks
export const { useAddSolutionMutation, useUpdateSolutionMutation } = solutionsApi;

// Combine the reducers
export const solutionsReducer = {
  [solutionsApi.reducerPath]: solutionsApi.reducer,
  solutions: solutionsSlice.reducer,
};
