// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
// Types
import { ReleaseNote } from "./types";
// Schemas
import { ReleaseNoteSchema } from "./schema";
// Zod
import { z } from "zod";

// Create the API slice
export const releaseNotesApi = createApi({
  reducerPath: "releaseNotesApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["ReleaseNotes"],
  endpoints: (builder) => ({
    /***** --- Get Release Notes Query --- *****/
    getReleaseNotes: builder.query<ReleaseNote[], void>({
      query: () => "/releaseNotes",
      extraOptions: {
        dataSchema: z.array(ReleaseNoteSchema),
        skipAuthentication: true,
      },
    }),
  }),
});

// Export hooks
export const { useGetReleaseNotesQuery } = releaseNotesApi;

// Export Reducer
export const releaseNotesReducer = {
  [releaseNotesApi.reducerPath]: releaseNotesApi.reducer,
};
