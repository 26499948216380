// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
// Types
import { KnowledgeGraph, KnowledgeGraphPolling } from "./types";
// Schemas
import { KnowledgeGraphSchema } from "./schemas";

// Create the API slice
export const knowledgeGraphApi = createApi({
  reducerPath: "knowledgeGraphApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["KnowledgeGraph"],
  endpoints: (builder) => ({
    /***** --- Generate Knowledge Graph Mutation --- *****/
    generateKnowledgeGraph: builder.mutation<
      KnowledgeGraph,
      {
        studyId: string;
      }
    >({
      query: ({ studyId }) => ({
        url: `/knowledgeGraph/generate_graph_data/${studyId}`,
        method: "GET",
      }),
      extraOptions: {
        dataSchema: KnowledgeGraphSchema,
      },
    }),
    /***** --- Get Knowledge Graph Query --- *****/
    getKnowledgeGraph: builder.query<KnowledgeGraphPolling, { studyId: string }>({
      query: ({ studyId }) => `/knowledgeGraph/graph_visualization/${studyId}/done`,
      providesTags: ["KnowledgeGraph"],
      extraOptions: {
        dataSchema: KnowledgeGraphSchema,
        skipAuthentication: true,
      },
    }),
  }),
});

// Export hooks
export const { useGenerateKnowledgeGraphMutation, useGetKnowledgeGraphQuery } = knowledgeGraphApi;

// Combine the reducers
export const knowledgeGraphReducer = {
  [knowledgeGraphApi.reducerPath]: knowledgeGraphApi.reducer,
};
