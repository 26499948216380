import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { userInterviewMessageSchema } from "./schemas";
import { disconnectUserInterviewsWS, userInterviewsApi } from "./slice";
import { handleUserInterviewMessage } from "./utils/hanlde-user-interview-message";
import { UnknownAction } from "@reduxjs/toolkit";

const onMessage: WebSocketConnectionConfig<typeof userInterviewMessageSchema>["onMessage"] = (
  message,
  store,
  resourceId
) => {
  if (message.type === "error" && resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }

  if (resourceId) {
    handleUserInterviewMessage(resourceId, message);
  }
};

const onClose: WebSocketConnectionConfig<typeof userInterviewMessageSchema>["onClose"] = (
  store,
  resourceId,
  wasClosed
) => {
  if (wasClosed && wasClosed.by === "error" && store && resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }

  if (wasClosed && wasClosed.by === "server" && store && resourceId) {
    // run fetch for user interviews
    store.dispatch(
      userInterviewsApi.endpoints.getUserInterviewAfterStreaming.initiate({
        interviewId: resourceId,
      }) as unknown as UnknownAction
    );

    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }
};

const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof userInterviewMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }
};

export const userInterviewsWSConnectionConfig: WebSocketConnectionConfig<
  typeof userInterviewMessageSchema
> = {
  path: "/userInterviews/stream/",
  messageSchema: userInterviewMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
