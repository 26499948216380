// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import {
  DoneUserInterview,
  InterviewAnnotation,
  UserInterview,
  UserInterviewInAnyPhase,
  UserInterviewMessage,
  UserInterviewState,
} from "./types";
// Schemas
import { DoneUserInterviewSchema, UserInterviewInAnyPhaseSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// WebSocket config
import { WebSocketConnectionConfigKeys, WasClosed } from "store/middleware/websockets/types";
// Utils
import { processUserInterviewMessage } from "./utils/process-user-interview-message";
// Store modules
import { historyApi } from "store/modules/history/slice";
import { handleStudyType } from "store/utils/handle-study-stategy";
import { StudyStrategyCodes } from "store/utils/get-study-strategy-code";
// Zod
import { z } from "zod";

export let BATCH_SIZE = 5;

// Create the API slice
export const userInterviewsApi = createApi({
  reducerPath: "userInterviewsApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  endpoints: (builder) => ({
    generateUserInterviews: builder.mutation<
      UserInterviewInAnyPhase[],
      {
        projectId: string;
        audienceIds?: string[];
        problemsIds?: string[];
        solutionId?: string;
        customScript?: string;
        generatedSyntheticUsersIds?: string[];
        researchGoalId?: string;
        uploadedFiles?: string[];
        interviews: number;
        studyStrategy: StudyStrategyCodes;
        language?: string;
        ongoingWalkthrough?: boolean;
      }
    >({
      query: (body) => {
        const {
          projectId,
          audienceIds,
          problemsIds,
          solutionId,
          customScript,
          generatedSyntheticUsersIds,
          researchGoalId,
          uploadedFiles,
          interviews,
          studyStrategy,
          language,
          ongoingWalkthrough,
        } = body;

        return {
          url: `/userInterviews/generateAfterPersonaSculptor/${projectId}`,
          method: "POST",
          body: {
            syntheticUsersIds: audienceIds,
            problemsIds,
            solutionId,
            customScript,
            generatedSyntheticUsersIds,
            researchGoalId,
            studyStrategy: handleStudyType(studyStrategy),
            language,
            ongoingWalkthrough,
            uploadedFiles,
          },
          params: {
            quantity: interviews,
          },
        };
      },
      async onQueryStarted(
        { projectId, interviews, researchGoalId },
        { queryFulfilled, dispatch }
      ) {
        const response = await queryFulfilled;
        if (response.data) {
          // refetch history
          dispatch(historyApi.endpoints.getHistory.initiate({ projectId }));
          //set batch size to the number of interviews (except for research goal cause each question/answer is a full message)
          BATCH_SIZE = researchGoalId ? 1 : interviews;
          // connect to the websocket with a delay of 10 seconds
          let delay = 0;
          response.data.forEach((userInterview) => {
            setTimeout(() => {
              dispatch(connectUserInterviewWS(userInterview.userInterviewId));
              delay += 10000;
            }, delay);
          });
        }
      },
      extraOptions: {
        dataSchema: z.array(UserInterviewInAnyPhaseSchema),
      },
    }),
    /***** --- Regenerate User Interview Mutation --- *****/
    regenerateUserInterview: builder.mutation<UserInterviewInAnyPhase, { interviewId: string }>({
      query: ({ interviewId }) => ({
        url: `/userInterviews/regenerate/${interviewId}`,
        method: "POST",
      }),
      async onQueryStarted({ interviewId }, { queryFulfilled, dispatch }) {
        const response = await queryFulfilled;
        if (response.data) {
          dispatch(connectUserInterviewWS(interviewId));
        }
      },
      extraOptions: {
        dataSchema: UserInterviewInAnyPhaseSchema,
      },
    }),
    /***** --- Get User Interview After Streaming Query --- *****/
    getUserInterviewAfterStreaming: builder.query<DoneUserInterview, { interviewId: string }>({
      query: ({ interviewId }) => ({
        url: `/userInterviews/${interviewId}`,
        method: "GET",
      }),
      extraOptions: {
        dataSchema: DoneUserInterviewSchema,
      },
    }),
    /***** --- Give User Interview Feedback Mutation --- *****/
    giveUserInterviewFeedback: builder.mutation<void, { interviewId: string; helpful: boolean }>({
      query: ({ interviewId, helpful }) => ({
        url: `/userInterviews/feedback/${interviewId}`,
        method: "POST",
        body: { helpful },
      }),
    }),
    /***** --- Add User Interview Annotation Mutation --- *****/
    addUserInterviewAnnotation: builder.mutation<
      InterviewAnnotation,
      {
        conversationId: string;
        userInterviewId: string;
        annotation: string;
        startIndex: number;
        endIndex: number;
        label: string;
      }
    >({
      query: ({ conversationId, userInterviewId, annotation, startIndex, endIndex, label }) => ({
        url: `/conversations/annotations`,
        method: "POST",
        body: {
          conversation_id: conversationId,
          user_interview_id: userInterviewId,
          annotation,
          start_index: startIndex,
          end_index: endIndex,
          label,
        },
      }),
    }),
    /***** --- Update User Interview Annotation Label Mutation --- *****/
    updateUserInterviewAnnotationLabel: builder.mutation<
      InterviewAnnotation,
      { annotationId: string; label: string }
    >({
      query: ({ annotationId, label }) => ({
        url: `/conversations/annotations/${annotationId}/label`,
        method: "PUT",
        body: label,
      }),
    }),
    /***** --- Delete User Interview Annotation Mutation --- *****/
    deleteUserInterviewAnnotation: builder.mutation<
      void,
      { annotationId: string; userInterviewId: string }
    >({
      query: ({ annotationId }) => ({
        url: `/conversations/annotations/${annotationId}`,
        method: "DELETE",
      }),
    }),
  }),
});

// Create the regular slice
const userInterviewsSlice = createSlice({
  name: "userInterviews",
  initialState,
  reducers: {
    /***** --- Reset User Interviews --- *****/
    resetUserInterviews: () => initialState,
    /***** --- Set Study User Interviews --- *****/
    setStudyUserInterviews: (state, action: PayloadAction<{ userInterviews: UserInterview[] }>) => {
      state.data = action.payload.userInterviews
        ? action.payload.userInterviews.reduce<{
            [key: string]: UserInterviewState;
          }>((acc, userInterview) => {
            acc[userInterview.userInterviewId] = {
              firstAppearedAt: new Date(userInterview.createdAt),
              data: userInterview,
            };
            return acc;
          }, {})
        : undefined;
    },
    /***** --- Handle WebSocket Connection --- *****/
    connectUserInterviewWS: {
      prepare: (userInterviewId: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.UserInterviews}-${userInterviewId}`,
          resourceId: userInterviewId,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle WebSocket Disconnection --- *****/
    disconnectUserInterviewsWS: {
      prepare: (userInterviewId: string, wasClosed?: WasClosed) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.UserInterviews}-${userInterviewId}`,
          resourceId: userInterviewId,
          wasClosed,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle Streaming User Interviews --- *****/
    streamUserInterviews: (
      state,
      action: PayloadAction<{ userInterviewId: string; messages: UserInterviewMessage[] }>
    ) => {
      const { userInterviewId, messages } = action.payload;
      const interview = state.data?.[userInterviewId];

      if (interview) {
        let currentUserInterview = Array.isArray(interview.data.userInterview)
          ? interview.data.userInterview
          : [interview.data.userInterview];

        messages.forEach((message) => {
          const { summedUserInterviewText } = processUserInterviewMessage(
            message,
            currentUserInterview,
            currentUserInterview.length - 1
          );
          currentUserInterview = summedUserInterviewText;
        });

        interview.data.userInterview = currentUserInterview;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.generateUserInterviews.matchPending,
          userInterviewsApi.endpoints.regenerateUserInterview.matchPending,
          userInterviewsApi.endpoints.getUserInterviewAfterStreaming.matchPending
        ),
        (state) => {
          state.loading += 1;
        }
      )
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.generateUserInterviews.matchFulfilled,
          userInterviewsApi.endpoints.generateUserInterviews.matchRejected,
          userInterviewsApi.endpoints.regenerateUserInterview.matchFulfilled,
          userInterviewsApi.endpoints.regenerateUserInterview.matchRejected,
          userInterviewsApi.endpoints.getUserInterviewAfterStreaming.matchFulfilled,
          userInterviewsApi.endpoints.getUserInterviewAfterStreaming.matchRejected
        ),
        (state) => {
          state.loading -= 1;
        }
      )
      /***** --- Handle Generate User Interviews Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.generateUserInterviews.matchFulfilled,
        (state, action) => {
          const indexedUserInterviews = action.payload.reduce((acc, userInterview) => {
            const {
              userInterviewId,
              projectId,
              syntheticUserId,
              createdAt,
              generatedSyntheticUserPersonalityDetails,
            } = userInterview;

            return {
              ...acc,
              [userInterviewId]: {
                loading: true,
                firstAppearedAt: new Date(createdAt),
                data: {
                  userInterviewId,
                  projectId,
                  syntheticUserId,
                  createdAt,
                  generatedSyntheticUserPersonalityDetails,
                  userInterviewWithTopics: [],
                  userInterviewWithTypes: [],
                  topics: [],
                },
              },
            };
          }, {});

          state.data = indexedUserInterviews;
        }
      )
      /***** --- Handle Regenerate User Interview Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.regenerateUserInterview.matchFulfilled,
        (state, action) => {
          const { userInterviewId } = action.payload;
          const prevInterview = state.data?.[userInterviewId];

          if (prevInterview) {
            state.data = {
              ...state.data,
              [userInterviewId]: {
                ...prevInterview,
                loading: true,
                error: undefined,
                data: {
                  ...prevInterview.data,
                  userInterview: "",
                  userInterviewWithTopics: [],
                  userInterviewWithTypes: [],
                  topics: [],
                },
              },
            };
          }
        }
      )
      /***** --- Handle Get User Interview After Streaming Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.getUserInterviewAfterStreaming.matchFulfilled,
        (state, action) => {
          const { interviewId } = action.meta.arg.originalArgs;
          const prevInterview = state.data?.[interviewId];

          if (state.data && prevInterview) {
            state.data[interviewId] = {
              ...prevInterview,
              loading: false,
              data: {
                ...action.payload,
                annotations: [],
              },
            };
          }
        }
      )
      /***** --- Handle Give User Interview Feedback Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.giveUserInterviewFeedback.matchFulfilled,
        (state, action) => {
          const { interviewId, helpful } = action.meta.arg.originalArgs;
          const prevInterview = state.data?.[interviewId];

          if (prevInterview) {
            state.data = {
              ...state.data,
              [interviewId]: {
                ...prevInterview,
                data: {
                  ...prevInterview.data,
                  helpful,
                },
              },
            };
          }
        }
      )
      /***** --- Handle Add User Interview Annotation Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.addUserInterviewAnnotation.matchFulfilled,
        (state, action) => {
          const { userInterviewId } = action.payload;
          const prevInterview = state.data?.[userInterviewId];

          if (prevInterview) {
            state.data = {
              ...state.data,
              [userInterviewId]: {
                ...prevInterview,
                data: {
                  ...prevInterview.data,
                  annotations: [...(prevInterview.data.annotations || []), action.payload],
                },
              },
            };
          }
        }
      )
      /***** --- Handle Update User Interview Annotation Label Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.updateUserInterviewAnnotationLabel.matchFulfilled,
        (state, action) => {
          const { userInterviewId } = action.payload;
          const prevInterview = state.data?.[userInterviewId];

          if (prevInterview) {
            state.data = {
              ...state.data,
              [userInterviewId]: {
                ...prevInterview,
                data: {
                  ...prevInterview.data,
                  annotations: prevInterview.data.annotations?.map((annotation) =>
                    annotation.id === action.payload.id ? action.payload : annotation
                  ),
                },
              },
            };
          }
        }
      )
      /***** --- Handle Delete User Interview Annotation Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.deleteUserInterviewAnnotation.matchFulfilled,
        (state, action) => {
          const { userInterviewId, annotationId } = action.meta.arg.originalArgs;
          const prevInterview = state.data?.[userInterviewId];

          if (prevInterview) {
            state.data = {
              ...state.data,
              [userInterviewId]: {
                ...prevInterview,
                data: {
                  ...prevInterview.data,
                  annotations: prevInterview.data.annotations?.filter(
                    (annotation) => annotation.id !== annotationId
                  ),
                },
              },
            };
          }
        }
      )
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.generateUserInterviews.matchRejected,
          userInterviewsApi.endpoints.regenerateUserInterview.matchRejected,
          userInterviewsApi.endpoints.getUserInterviewAfterStreaming.matchRejected,
          userInterviewsApi.endpoints.giveUserInterviewFeedback.matchRejected,
          userInterviewsApi.endpoints.addUserInterviewAnnotation.matchRejected,
          userInterviewsApi.endpoints.updateUserInterviewAnnotationLabel.matchRejected,
          userInterviewsApi.endpoints.deleteUserInterviewAnnotation.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const {
  connectUserInterviewWS,
  disconnectUserInterviewsWS,
  streamUserInterviews,
  resetUserInterviews,
  setStudyUserInterviews,
} = userInterviewsSlice.actions;

// Export hooks
export const {
  useGenerateUserInterviewsMutation,
  useRegenerateUserInterviewMutation,
  useGiveUserInterviewFeedbackMutation,
  useAddUserInterviewAnnotationMutation,
  useUpdateUserInterviewAnnotationLabelMutation,
  useDeleteUserInterviewAnnotationMutation,
} = userInterviewsApi;

// Combine the reducers
export const userInterviewsReducer = {
  [userInterviewsApi.reducerPath]: userInterviewsApi.reducer,
  userInterviews: userInterviewsSlice.reducer,
};
