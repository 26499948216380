// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { ResearchGoal } from "./types";
// Schemas
import { ResearchGoalSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";

// Create the API slice
export const researchGoalApi = createApi({
  reducerPath: "researchGoalApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["ResearchGoal"],
  endpoints: (builder) => ({
    /***** --- Add Research Goal Mutation --- *****/
    addResearchGoal: builder.mutation<ResearchGoal, { projectId: string; description: string }>({
      query: ({ projectId, description }) => ({
        url: `/researchGoals`,
        method: "POST",
        body: { projectId, description },
      }),
      extraOptions: {
        dataSchema: ResearchGoalSchema,
      },
    }),
  }),
});

// Create the regular slice
export const researchGoalSlice = createSlice({
  name: "researchGoal",
  initialState,
  reducers: {
    /***** --- Reset Research Goal --- *****/
    resetResearchGoal: () => initialState,
    /***** --- Set Research Goal --- *****/
    setResearchGoal: (state, action: PayloadAction<ResearchGoal>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Add Research Goal Pending --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchPending, (state, action) => {
        const { requestId } = action.meta;
        const { description } = action.meta.arg.originalArgs;
        // Create an optimistic research goal
        const optimisticResearchGoal = {
          id: requestId,
          description,
          createdAt: new Date().toISOString(),
        };
        // Set the optimistic research goal
        state.data = optimisticResearchGoal;
        state.loading = true;
      })
      /***** --- Handle Add Research Goal Fulfilled --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchFulfilled, (state, action) => {
        const completedResearchGoal = action.payload;
        // Set the completed research goal
        state.data = completedResearchGoal;
        state.loading = false;
      })
      /***** --- Handle Add Research Goal Rejected --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchRejected, (state, action) => {
        state.error = parseError(action.error);
        state.loading = false;
      });
  },
});

// Export actions
export const { setResearchGoal, resetResearchGoal } = researchGoalSlice.actions;

// Export hooks
export const { useAddResearchGoalMutation } = researchGoalApi;

// Combine the reducers
export const researchGoalReducer = {
  [researchGoalApi.reducerPath]: researchGoalApi.reducer,
  researchGoal: researchGoalSlice.reducer,
};
