// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { RagFile, RagFilesStats, UploadRagFile } from "./types";
import { RcFile } from "antd/es/upload";
// Schemas
import { RagFileSchema, RagFilesStatsSchema, UploadRagFileSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Zod
import { z } from "zod";

// Create the API slice
export const ragApi = createApi({
  reducerPath: "ragApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["RagFiles"],
  endpoints: (builder) => ({
    /***** --- Get Rag Files Query --- *****/
    getRagFiles: builder.query<RagFile[], { projectId: string }>({
      query: ({ projectId }) => `/upload/projects/${projectId}`,
      transformResponse: (response: RagFile[]) => {
        return response.sort((a, b) => b.uploadedAt.localeCompare(a.uploadedAt));
      },
      providesTags: ["RagFiles"],
      extraOptions: {
        dataSchema: z.array(RagFileSchema),
      },
    }),
    /***** --- Get Rag Files Stats Query --- *****/
    getRagFilesStats: builder.query<RagFilesStats, { projectId: string }>({
      query: ({ projectId }) => `/upload/${projectId}/stats`,
      providesTags: ["RagFiles"],
      extraOptions: {
        dataSchema: RagFilesStatsSchema,
      },
    }),
    /***** --- Upload Rag File Mutation --- *****/
    uploadRagFile: builder.mutation<
      UploadRagFile,
      {
        projectId: string;
        file: RcFile;
        userEmail: string;
        onSuccess: () => void;
        onError: () => void;
      }
    >({
      query: ({ projectId, file }) => ({
        url: "/upload",
        method: "POST",
        body: { project_id: projectId, filename: file.name },
      }),
      async onQueryStarted({ file, onSuccess, onError }, { dispatch, queryFulfilled, requestId }) {
        try {
          const { data: uploadRagFile } = await queryFulfilled;

          const { url, fields } = uploadRagFile.presigned;

          const formData = new FormData();

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });

          formData.append("file", file);

          const upload = await fetch(url, {
            method: "POST",
            body: formData,
          });

          if (upload.ok) {
            dispatch(
              ragApi.endpoints.markUploadAsCompleted.initiate({
                fileId: uploadRagFile.id,
                temporaryId: requestId,
                onSuccess,
                onError,
              })
            );
          } else {
            onError();
            // throw error to be handled by the reducer
            throw new Error("Failed to upload file");
          }
        } catch (error) {
          onError();
          // throw error to be handled by the reducer
          throw error;
        }
      },
      extraOptions: {
        dataSchema: UploadRagFileSchema,
      },
    }),
    /***** --- Mark Upload As Completed Mutation --- *****/
    markUploadAsCompleted: builder.mutation<
      RagFile,
      { fileId: string; temporaryId: string; onSuccess: () => void; onError: () => void }
    >({
      query: ({ fileId }) => ({
        url: `/upload/${fileId}/status`,
        method: "POST",
      }),
      async onQueryStarted({ onSuccess, onError }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          onSuccess();
        } catch (error) {
          onError();
          // throw error to be handled by the reducer
          throw error;
        }
      },
      extraOptions: {
        dataSchema: RagFileSchema,
      },
    }),
    /***** --- Delete Rag File Mutation --- *****/
    deleteRagFile: builder.mutation<void, { fileId: string }>({
      query: ({ fileId }) => ({
        url: `/upload/${fileId}`,
        method: "DELETE",
      }),
    }),
  }),
});

// Create the regular slice
export const ragSlice = createSlice({
  name: "rag",
  initialState,
  reducers: {
    /***** --- Reset Rag --- *****/
    resetRag: () => initialState,
    /***** --- Set Rag Files --- *****/
    setRagFiles: (state, action: PayloadAction<RagFile[]>) => {
      return {
        ...state,
        data: {
          ragFiles: action.payload,
          selectedRagFiles: state.data?.selectedRagFiles || [],
        },
      };
    },
    /***** --- Set Rag Files Stats --- *****/
    setRagFilesStats: (state, action: PayloadAction<RagFilesStats>) => {
      if (!state.data?.ragFiles) return state;

      state.data.ragFiles = state.data.ragFiles.map((ragFile) => {
        const file = Object.entries(action.payload).find(([id]) => id == ragFile.id);

        if (!file) return ragFile;

        const total = file[1].total;
        const current = file[1].current;

        const processingPercentage = total === 0 ? 0 : Math.round((current / total) * 100);

        return {
          ...ragFile,
          processingPercentage,
        };
      });
    },
    /***** --- Set Selected Rag Files --- *****/
    setSelectedRagFiles: (state, action: PayloadAction<string[]>) => {
      if (!state.data) return state;

      state.data.selectedRagFiles =
        state.data.ragFiles?.filter((file) => action.payload.includes(file.id)) || [];
    },
    /***** --- Reset Selected Rag Files --- *****/
    resetSelectedRagFiles: (state) => {
      if (!state.data) return state;

      state.data.selectedRagFiles = [];
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          ragApi.endpoints.getRagFiles.matchPending,
          ragApi.endpoints.uploadRagFile.matchPending,
          ragApi.endpoints.deleteRagFile.matchPending,
          ragApi.endpoints.markUploadAsCompleted.matchPending
        ),
        (state) => {
          state.loading += 1;
        }
      )
      .addMatcher(
        isAnyOf(
          ragApi.endpoints.getRagFiles.matchFulfilled,
          ragApi.endpoints.getRagFiles.matchRejected,
          ragApi.endpoints.uploadRagFile.matchFulfilled,
          ragApi.endpoints.uploadRagFile.matchRejected,
          ragApi.endpoints.deleteRagFile.matchFulfilled,
          ragApi.endpoints.deleteRagFile.matchRejected,
          ragApi.endpoints.markUploadAsCompleted.matchFulfilled,
          ragApi.endpoints.markUploadAsCompleted.matchRejected
        ),
        (state) => {
          state.loading -= 1;
        }
      )
      /***** --- Handle Get Rag Files Fulfilled --- *****/
      .addMatcher(ragApi.endpoints.getRagFiles.matchFulfilled, (state, action) => {
        state.data = {
          ragFiles: action.payload,
          selectedRagFiles: state.data?.selectedRagFiles || [],
        };
      })
      /***** --- Handle Get Rag Files Stats Fulfilled --- *****/
      .addMatcher(ragApi.endpoints.getRagFilesStats.matchFulfilled, (state, action) => {
        if (!state.data?.ragFiles) return state;

        state.data.ragFiles = state.data.ragFiles.map((ragFile) => {
          const file = Object.entries(action.payload).find(([id]) => id == ragFile.id);

          if (!file) return ragFile;

          const total = file[1].total;
          const current = file[1].current;

          const processingPercentage = total === 0 ? 0 : (current / total) * 100;

          return {
            ...ragFile,
            processingPercentage,
          };
        });
      })
      /***** --- Handle Upload Rag File Pending --- *****/
      .addMatcher(ragApi.endpoints.uploadRagFile.matchPending, (state, action) => {
        const { requestId } = action.meta;
        const { file, userEmail, projectId } = action.meta.arg.originalArgs;
        // create a temporary file with the requestId as the id
        const temporaryFile = {
          id: requestId,
          filename: file.name,
          projectId,
          uploadedBy: userEmail,
          uploadedAt: new Date().toISOString(),
          status: "uploading",
        };

        // add the temporary file to the rag files
        state.data = {
          ragFiles: [temporaryFile, ...(state.data?.ragFiles || [])],
          selectedRagFiles: state.data?.selectedRagFiles || [],
        };
      })
      /***** --- Handle Upload Rag File Rejected --- *****/
      .addMatcher(ragApi.endpoints.uploadRagFile.matchRejected, (state, action) => {
        const { requestId } = action.meta;

        // remove the temporary file from the rag files
        state.data = {
          ragFiles: [...(state.data?.ragFiles || []).filter((file) => file.id !== requestId)],
          selectedRagFiles: state.data?.selectedRagFiles || [],
        };
      })
      /***** --- Handle Upload Rag File Completed --- *****/
      .addMatcher(ragApi.endpoints.markUploadAsCompleted.matchFulfilled, (state, action) => {
        const { temporaryId } = action.meta.arg.originalArgs;
        // create a completed file and initialize processingPercentage to 0
        const completedFile = {
          ...action.payload,
          processingPercentage: 0,
        };

        // update the temporary file with the completed file
        state.data = {
          ragFiles: [
            ...(state.data?.ragFiles || []).map((file) =>
              file.id === temporaryId ? completedFile : file
            ),
          ],
          selectedRagFiles: state.data?.selectedRagFiles || [],
        };
      })
      /***** --- Handle Upload Rag File Rejected --- *****/
      .addMatcher(ragApi.endpoints.markUploadAsCompleted.matchRejected, (state, action) => {
        const { temporaryId } = action.meta.arg.originalArgs;

        // remove the temporary file from the rag files
        state.data = {
          ragFiles: [...(state.data?.ragFiles || []).filter((file) => file.id !== temporaryId)],
          selectedRagFiles: state.data?.selectedRagFiles || [],
        };
      })
      /***** --- Handle Delete Rag File Fulfilled --- *****/
      .addMatcher(ragApi.endpoints.deleteRagFile.matchFulfilled, (state, action) => {
        const { fileId } = action.meta.arg.originalArgs;
        if (state.data?.ragFiles) {
          state.data.ragFiles = state.data.ragFiles.filter((file) => file.id !== fileId);
        }
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          ragApi.endpoints.getRagFiles.matchRejected,
          ragApi.endpoints.getRagFilesStats.matchRejected,
          ragApi.endpoints.uploadRagFile.matchRejected,
          ragApi.endpoints.deleteRagFile.matchRejected,
          ragApi.endpoints.markUploadAsCompleted.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetRag, setSelectedRagFiles, resetSelectedRagFiles } = ragSlice.actions;

// Combine the reducers
export const ragReducer = {
  [ragApi.reducerPath]: ragApi.reducer,
  rag: ragSlice.reducer,
};
