// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { History } from "./types";
// Schemas
import { HistorySchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Zod
import { z } from "zod";

// Create the API slice
export const historyApi = createApi({
  reducerPath: "historyApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["History"],
  endpoints: (builder) => ({
    /***** --- Get History Query --- *****/
    getHistory: builder.query<History[], { projectId: string }>({
      query: ({ projectId }) => `/studies/history/${projectId}`,
      extraOptions: {
        dataSchema: z.array(HistorySchema),
      },
    }),
  }),
});

// Create the regular slice
export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    /***** --- Reset History --- *****/
    resetHistory: () => initialState,
    /***** --- Update Study History --- *****/
    updateStudyHistory: (state, action: PayloadAction<{ id: string; description: string }>) => {
      if (state.data) {
        const { id, description } = action.payload;
        state.data = state.data.map((study) =>
          study.id === id ? { ...study, description } : study
        );
      }
    },
    /***** --- Delete Study History --- *****/
    deleteStudyHistory: (state, action: PayloadAction<{ id: string }>) => {
      if (state.data) {
        state.data = state.data.filter((study) => study.id !== action.payload.id);
      }
    },
  },
  extraReducers: (builder) => {
    /***** --- Handle Loading --- *****/
    builder
      .addMatcher(historyApi.endpoints.getHistory.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(
          historyApi.endpoints.getHistory.matchFulfilled,
          historyApi.endpoints.getHistory.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Fulfilled --- *****/
      .addMatcher(historyApi.endpoints.getHistory.matchFulfilled, (state, action) => {
        state.data = action.payload;
      })
      /**Handle Rejected */
      .addMatcher(historyApi.endpoints.getHistory.matchRejected, (state, action) => {
        const error = parseError(action.error);
        state.error = error;
      });
  },
});

// Export actions
export const { resetHistory, updateStudyHistory, deleteStudyHistory } = historySlice.actions;

// Export hooks
export const { useGetHistoryQuery } = historyApi;

// Combine the reducers
export const historyReducer = {
  [historyApi.reducerPath]: historyApi.reducer,
  history: historySlice.reducer,
};
