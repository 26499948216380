import { faCheck, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Result, theme } from "antd";
import { sendEmailVerification, signOut } from "firebase/auth";
import { getAnalytics } from "logic/analytics/analytics";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "logic/auth-context";
import { getFirebaseAuth } from "../../../logic/internals/apis/firebase/firebase-auth";
import { TransportPhase } from "../../../logic/internals/transports/transported-data/transport-phases";
import { TransportedData } from "../../../logic/internals/transports/transported-data/transported-data-types";
import { INDEX_ROUTE } from "../../../templates/index/index-routes";
import { AntIcon } from "../../ui-kit/components/ant-icon";

export function EmailVerificationGate(props: { children: ReactNode }) {
  const { token } = theme.useToken();
  const auth = useAuthContext();
  const router = useRouter();
  const analytics = getAnalytics();
  const [secondsTillUnlock, setSecondsTillUnblock] = useState(20);

  const [verificationLinkState, setVerificationLinkState] = useState<
    TransportedData<undefined> | { status: "too-many-requests" }
  >({ status: TransportPhase.NotInitialized });

  const [logoutState, setLogoutState] = useState({
    status: TransportPhase.NotInitialized,
  });

  const user = useMemo(() => auth.session.user, [auth.session.user]);

  const emailNeedsVerification = useMemo(() => !user?.emailVerified, [user]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsTillUnlock > 0) {
        setSecondsTillUnblock((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [secondsTillUnlock]);

  return (
    <>
      {emailNeedsVerification && user ? (
        <Result
          status="info"
          title="Verify your email"
          extra={
            <>
              <div style={{ marginBottom: token.marginLG }}>
                <p>
                  To complete your registration and start using our app, we need you to verify your
                  email address. <br />
                  {"We've"} sent you an email with the verification link.
                </p>
                <p>{`If you didn't receive the verification email, please check your spam folder.`}</p>
                <p>Reload this page after {"you've"} opened the verification link.</p>
              </div>
              <div style={{ marginBottom: token.marginSM }}>
                <Button
                  style={{ marginRight: token.marginSM }}
                  loading={logoutState.status === TransportPhase.Loading}
                  onClick={async () => {
                    analytics.track("verify-your-email:go-back");

                    setLogoutState({ status: TransportPhase.Loading });

                    await signOut(getFirebaseAuth());

                    setLogoutState({ status: TransportPhase.Done });

                    router.push(INDEX_ROUTE.getHref());
                  }}
                >
                  Go back to the home page
                </Button>
                <Button
                  icon={
                    <AntIcon
                      component={() => (
                        <FontAwesomeIcon
                          icon={
                            verificationLinkState.status === TransportPhase.Done
                              ? faCheck
                              : faEnvelope
                          }
                        />
                      )}
                    />
                  }
                  type="primary"
                  loading={
                    verificationLinkState.status === TransportPhase.Loading ||
                    secondsTillUnlock !== 0
                  }
                  onClick={async () => {
                    if (verificationLinkState.status !== TransportPhase.Done) {
                      analytics.track("verify-your-email:resend-verification-link");
                    }

                    setVerificationLinkState({
                      status: TransportPhase.Loading,
                    });

                    try {
                      await sendEmailVerification(user, {
                        url: location.origin,
                      });

                      setVerificationLinkState({
                        status: TransportPhase.Done,
                        data: undefined,
                      });
                    } catch (err) {
                      const error = err as { [key: string]: unknown };

                      if (error.code === "auth/too-many-requests") {
                        setVerificationLinkState({
                          status: "too-many-requests",
                        });
                        setSecondsTillUnblock(20);
                      } else {
                        throw error;
                      }
                    }
                  }}
                >
                  {verificationLinkState.status === TransportPhase.Done
                    ? "Sent"
                    : "Send verification link again"}
                  {secondsTillUnlock === 0 ? "" : ` (${secondsTillUnlock})`}
                </Button>
              </div>
              {verificationLinkState.status === "too-many-requests" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Alert
                    type="error"
                    message="Too many verification emails were sent to your email."
                    description="Wait a few moments before sending a new verification email."
                  />
                </div>
              )}
            </>
          }
        />
      ) : (
        props.children
      )}
    </>
  );
}
