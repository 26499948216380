import { MiddlewareAPI } from "@reduxjs/toolkit";
import { z } from "zod";

export enum WebSocketConnectionConfigKeys {
  UserInterviews = "userInterviews",
  UserInterviewConversation = "userInterviewConversations",
  Summary = "summary",
  SummaryConversation = "summaryConversations",
}

export type WasClosed = { by: "error" | "server" | "client" };

// Define a type for the WebSocket connection configuration
export type WebSocketConnectionConfig<S extends z.ZodType<unknown>> = {
  path: string;
  messageSchema: S;
  onClose: (store: MiddlewareAPI, resourceId: string, wasClosed?: WasClosed) => void;
  onMessage: (message: z.TypeOf<S>, store: MiddlewareAPI, resourceId?: string) => void;
  onInvalidMessageReceived: (store: MiddlewareAPI, resourceId?: string) => void;
};

// Define a type for multiple WebSocket connection configurations
export type WebSocketConnectionsConfig = {
  [key in WebSocketConnectionConfigKeys]: WebSocketConnectionConfig<z.ZodType<unknown>>;
};

export type ConnectWebSocketAction = {
  type: string;
  payload: {
    key: `${WebSocketConnectionConfigKeys}-${string}`;
    resourceId: string;
    queryParams?: string;
    wasClosed?: WasClosed;
    path?: string;
  };
};
