// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { GeneratedSyntheticUser } from "./types";
// Schemas
import { GeneratedSyntheticUserSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Zod
import { z } from "zod";

// Create the API slice
export const syntheticUsersApi = createApi({
  reducerPath: "syntheticUsersApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["SyntheticUsers"],
  endpoints: (builder) => ({
    /***** --- Generate Synthetic Users Mutation --- *****/
    generateSyntheticUsers: builder.mutation<
      { ids: Record<string, string[]> },
      {
        projectId: string;
        quantity: string;
        audiencesIds: string[];
        problemsIds: string[];
        solutionId: string | null;
        researchGoalId?: string;
        uploadedFilesIds?: string[];
      }
    >({
      query: ({
        projectId,
        quantity,
        audiencesIds,
        problemsIds,
        researchGoalId,
        solutionId,
        uploadedFilesIds,
      }) => ({
        url: `/generateSyntheticUsers/${projectId}`,
        method: "POST",
        body: {
          syntheticUsersIds: audiencesIds,
          problemsIds,
          researchGoalId,
          solutionId,
          uploadedFilesIds,
        },
        params: { quantity },
      }),
      extraOptions: {
        dataSchema: z.object({
          ids: z.record(z.string(), z.array(z.string())),
        }),
      },
    }),
    /***** --- Get Synthetic Users Query --- *****/
    getSyntheticUsers: builder.query<
      { status: string } | GeneratedSyntheticUser[],
      { projectId: string; ids: Record<string, string[]> }
    >({
      query: ({ projectId, ids }) => ({
        url: `/generateSyntheticUsers/${projectId}/done`,
        method: "POST",
        body: { ids },
      }),
      extraOptions: {
        dataSchema: z.union([
          z.array(GeneratedSyntheticUserSchema),
          z.object({ status: z.string() }),
        ]),
      },
    }),
    /***** --- Update Synthetic Users Mutation --- *****/
    updateSyntheticUsers: builder.mutation<
      GeneratedSyntheticUser,
      {
        syntheticUserId: string;
        data: string;
      }
    >({
      query: ({ syntheticUserId, data }) => ({
        url: `/generateSyntheticUsers/update/${syntheticUserId}`,
        method: "PATCH",
        body: data,
      }),
      extraOptions: {
        dataSchema: GeneratedSyntheticUserSchema,
      },
    }),
  }),
});

// Create the regular slice
export const syntheticUsersSlice = createSlice({
  name: "syntheticUsers",
  initialState,
  reducers: {
    /***** --- Reset Synthetic Users --- *****/
    resetSyntheticUsers: () => initialState,
    /***** --- Set Study Synthetic Users --- *****/
    setStudySyntheticUsers: (
      state,
      action: PayloadAction<{ syntheticUsers: GeneratedSyntheticUser[] }>
    ) => {
      state.data = {
        generatedUsers: action.payload.syntheticUsers,
        generatingIds: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchPending,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchFulfilled,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Generate Synthetic Users Fulfilled --- *****/
      .addMatcher(
        syntheticUsersApi.endpoints.generateSyntheticUsers.matchFulfilled,
        (state, action) => {
          state.data = {
            generatingIds: action.payload.ids,
          };
        }
      )
      /***** --- Handle Get Synthetic Users Fulfilled --- *****/
      .addMatcher(syntheticUsersApi.endpoints.getSyntheticUsers.matchFulfilled, (state, action) => {
        // if status in payload it means the synthetic users are still generating
        if ("status" in action.payload) return state;

        // if payload is an array it means the synthetic users generation is done
        state.data = {
          generatedUsers: action.payload,
          generatingIds: undefined,
        };
        state.loading = false;
      })
      /***** --- Handle Update Synthetic Users Fulfilled --- *****/
      .addMatcher(
        syntheticUsersApi.endpoints.updateSyntheticUsers.matchFulfilled,
        (state, action) => {
          state.data = {
            ...state.data,
            generatedUsers: state.data?.generatedUsers?.map((item) =>
              item.id === action.payload.id ? action.payload : item
            ),
          };
        }
      )
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.getSyntheticUsers.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetSyntheticUsers, setStudySyntheticUsers } = syntheticUsersSlice.actions;

// Export hooks
export const {
  useGenerateSyntheticUsersMutation,
  useGetSyntheticUsersQuery,
  useUpdateSyntheticUsersMutation,
} = syntheticUsersApi;

// Combine the reducers
export const syntheticUsersReducer = {
  [syntheticUsersApi.reducerPath]: syntheticUsersApi.reducer,
  syntheticUsers: syntheticUsersSlice.reducer,
};
